#custom-header {
    background: #1BAE70;
    padding: 0 10px 0 10px;
}

.ant-layout-header {
    height: 48px;
    line-height: 48px;
}

.header-actions {
    cursor: pointer;
}

.login-logo {
    width: 100px;
}

.text-white {
    color: #fff;
}

.logo img {
    height: 40px !important;
}

.logo a, .logo a:hover {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.header-icons {
    color: #fff;
    font-size: 20px;
}

.ant-layout-sider-trigger {
    background: #dfede5 !important;
    font-weight: bolder;
    font-size: 20px;
}

.content-wrapper {
    min-height: 80vh;
}

.login-card {
    margin-top: 25px;
    width: 30%;
}

.ios-badge {
    display: inline-block;
    overflow: hidden;
    background: url(https://linkmaker.itunes.apple.com/vi-vn/badge-lrg.svg?releaseDate=2019-12-02&kind=iossoftware&bubble=ios_apps) no-repeat;
    width: 135px;
    height: 40px;
    margin-left: 10px;
}

.android-badge img {
    height: 60px;
}

.ql-editor {
    min-height: 250px;
}

.sidebar-icon {
    font-size: 22px !important;
}

.sidebar-icon-font-awesome {
    font-size: 18px !important;
}

.ecom-menu .ant-menu-item,
.ecom-menu .ant-menu-submenu-title {
    padding-left: 14px !important;
}

.ecom-menu .ant-menu-item-only-child {
    padding-left: 46px !important;
}

.drawer-logo-mobile {
    height: 48px;
}

.item-action-btn {
    cursor: pointer;
}

.item-action-btn:hover {
    color: #188fff
}

.primary-color, .primary-color .ant-badge {
    color: #1BAE70;
}

.item-action-btn-remove {
    color: rgb(158, 39, 39);
    cursor: pointer;

}

.item-action-btn-remove:hover {
    color: red;
}

.site-page-header {
    padding-left: 0 !important;
    padding-bottom: 0;
}

.homepage-card {
    width: 100% !important;
}

.homepage-card .ant-card-cover img {
    width: 100%;
    height: 200px;
    object-fit: cover
}

.file-png-card .ant-image img,
.idea-list-card .ant-image img {
    background: #dedede;
    height: 200px;
    min-height: 200px;
    object-fit: cover;
    object-position: center;
    display: block;
    width: 100%;
}

.homepage-card .ant-card-body {
    text-align: center
}

.notification-list {
    max-height: 400px;
    overflow-y: auto;
}

.custom-col {
    width: 100% !important;
}

.custom-radio {
    display: block;
    height: 30px;
    line-height: 30px
}

#request-menu {
    margin-left: -24px !important;
    margin-right: -24px !important;
    height: max-content;
    display: flex;
    justify-content: center;
    background-color: #575f63;
}

#request-menu .ant-menu-item {
    text-align: center !important;
    min-width: 130px !important;
    padding-top: 12px !important;
    background-color: #575f63;
}

#request-menu .ant-menu-item-selected {
    background-color: #fff !important
}

#request-menu .ant-menu-item-selected span {
    color: #e47025 !important;
}

#request-menu .ant-menu-item span {
    display: block !important;
    word-break: break-all;
    font-size: 12px;
    line-height: 36px;
}

#request-menu .ant-menu-item .anticon {
    font-size: 40px !important;
    margin-right: 0 !important;
}



/* .ant-form-item-label {
    font-weight: bolder
} */

.am-notice-bar {
    margin-right: -10px !important;
}

.ant-layout-footer {
    padding-left: 0 !important
}

.module-cards {
    min-height: 140px;
    border-radius: 25px;
    border: none !important;
    color: #fff;
    background-color: #575f63;
}

.module-cards .ant-card-body {
    padding: 15px 20px 20px 25px !important;
}

.module-cards .anticon {
    font-size: 40px !important;
}

.module-cards .view-more-icon {
    font-size: initial !important;
}

.module-cards:hover {
    background-color: #e47025 !important;
}

.text-none {
    text-decoration: none !important
}

.product-card {
    border-radius: 15px;
}

.product-card .ant-card-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.product-img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px !important;
}

.price {
    font-weight: 700;
    color: #e74c3c;
}

.old-price {
    text-decoration: line-through;
    font-size: 12px;
}

.product-img-detail {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.slide .product-img-detail {
    width: 100% !important;
    height: 500px !important;
    object-fit: contain !important
}

.thumb .product-img-detail {
    height: 80px !important;
    object-fit: cover !important;
}

.carousel .slide {
    background-color: #f0f2f5 !important;
}

.thumbs.animated {
    padding: 0 !important;
    text-align: center;
}

.product-statistic-card-order-list {
    width: 16.5%;
}

.product-statistic-card-order-list .ant-card-body {
    padding: 5px 5px !important;
}

.product-statistic-card-order-list .ant-statistic-title {
    font-size: 14px;
}

.product-statistic-card-order-list .ant-statistic-content {
    font-size: 16px;
}

.product-statistic-card-dashboard {
    width: 14%;
}

.product-statistic-card-dashboard .ant-card-body {
    padding: 2px 15px !important;
}

.product-statistic-card {
    width: 16%;
}

.product-statistic-card .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card2 {
    width: 16.65%;
}

.product-statistic-card2 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card2 .ant-statistic-title {
    font-size: 12px;
}

.product-statistic-card2 .ant-statistic-content {
    font-size: 15px;
}

.product-statistic-card5 {
    width: 20%;
}

.product-statistic-card5 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card5 .ant-statistic-title {
    font-size: 12px;
}

.product-statistic-card5 .ant-statistic-content {
    font-size: 15px;
}

.product-statistic-card6 {
    width: 25%;
}

.product-statistic-card6 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card6 .ant-statistic-title {
    font-size: 12px;
}

.product-statistic-card6 .ant-statistic-content {
    font-size: 15px;
}

.product-statistic-card3 {
    width: 25%;
}

.product-statistic-card3 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card4 {
    width: 20%;
}

.product-statistic-card4 .ant-card-body {
    padding: 10px 15px !important;
}

.img-modal {
    width: unset !important;
    max-width: 90%;
    min-width: 520px;
}

.statistical-revenue_expenditure .ant-descriptions-view {
    border: none !important;
}

.statistical-revenue_expenditure .ant-descriptions-view table th,
.statistical-revenue_expenditure .ant-descriptions-view table td {
    padding: 5px 10px !important;
    background: none;
}

.statistical-revenue_expenditure .ant-card-head {
    background: #fafafa;
}

.table-button {
    margin-right: .6rem !important;
    margin-bottom: .5rem !important;
}

@media only screen and (max-width: 768px) {
    .login-card {
        margin-top: 0px !important;
        width: 100% !important;
    }

    .homepage-card {
        margin-top: 25px
    }

    #request-menu .ant-menu-item {
        text-align: center !important;
        max-width: 80px !important;
        padding-top: 20px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .product-default-img {
        display: none !important;
    }

    .slide .product-img-detail {
        height: 200px !important;
        width: 100% !important;
        object-fit: cover;
    }

    .table-filter, .table-button {
        margin-bottom: .5rem !important;
        width: 100% !important;
        margin-right: 0 !important;
    }

    .product-statistic-card {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card2 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card3 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card6 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card4 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card5 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card-order-list {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card-dashboard {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .fix-buttom-mobile, .training-img img {
        width: 100%;
    }

    .fix-width-card-idea .ant-card-extra {
        width: 100%;
    }

    .ant-list-vertical .ant-list-item-action>li {
        padding: 8px 16px;
        width: 100%;
    }
}

.filter-icon {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
    line-height: 32px;
    display: inline-block !important;
}

.widget-cards,
.module-cards {
    border-radius: 25px;
    border: none !important;
    color: #fff;
}

.widget-cards .ant-card-body {
    padding: 20px 15px !important;
    display: flex;
}

.card-blue {
    background-color: #1890ff
}

.card-green {
    background-color: #2ecc71
}

.card-red {
    background-color: #e74c3c
}

.card-orange {
    background-color: #f39c12
}

.card-yellow {
    background-color: #f1c40f
}

.card-violet {
    background-color: #9b59b6
}

.card-black {
    background-color: #2c3e50
}

.widget-icon {
    font-size: xx-large;
    margin-right: 10px;
}

.home-widget-icon {
    font-size: xx-large;
}

.widget-cards .ant-card-body .widget-big-text {
    font-size: 35px;
    line-height: 30px;
    margin-bottom: 3px;
}

.widget-cards .ant-card-body .widget-default-text {
    font-size: 25px;
}

.module-cards,
.module-card-wrapper {
    width: 100%;
    cursor: pointer;
}

.module-cards {
    height: 150px;
}

.module-cards h4 {
    color: #fff;
}

.success-btn {
    background-color: #27ae60;
    border-color: #27ae60;
}

.success-btn:hover {
    background-color: #2ecc71;
    border-color: #2ecc71;
}

.payment-config .ql-editor {
    min-height: 100px !important;
}

.strike-text {
    text-decoration: line-through;
}

.clear-button {
    background-color: #ecf0f1;
    border-color: #bdc3c7;
}

.clear-button:hover {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff;
}

.custom-ul {
    padding-inline-start: 0 !important;
    margin: 0;
    list-style: none;
}

.ecom-paragraph .ant-typography {
    margin: 0;
}

.ticket-response-input .ql-editor {
    min-height: 100px !important;
}

.customer-support-note-modal {
    width: unset !important;
    max-width: 90%;
    min-width: 520px;
}

.request-image {
    cursor: pointer;
    border: 2px #bdc3c7 solid;
}

.ant-descriptions-item-content {
    display: block !important;
}

.smallParent>p {
    margin: 3px;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    background: #fff;
    border-radius: 2px;
    border-bottom: 1px solid #c0c0c06c;
    overflow: hidden;
}

.alink {
    color: #007bff;
    margin-top: 10px;
    display: block;
    text-align: right;
}

/**Edit table cell*/
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 0px 5px 0px 5px;
    cursor: pointer;
    border: 1px solid transparent;
    min-height: 22px;
    border-radius: 4px;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

/** End Edit table cell*/

span.ant-descriptions-item-label {
    font-weight: bold;
}

/* .list-img-thumbnail {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    border: 1px solid #dedede;
} */

.list-img-thumbnail {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    border: 1px solid #dedede;
    padding: 5px;
    margin-bottom: 8px;
    width: 55px;
    height: 55px;
    overflow: hidden;
}

.list-img-thumbnail .ant-radio-wrapper {
    margin-right: 0;
}

.list-img-thumbnail span.ant-radio+* {
    padding-right: 0;
    padding-left: 0;
}

.list-img-thumbnail .btn-delete-img {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    background: #fff;
    cursor: pointer;
    font-size: 18px;
    color: red;
    opacity: 0.2;
}

.list-img-thumbnail .btn-delete-img2 {
    position: absolute;
    z-index: 99;
    top: -1px;
    right: -1px;
    background: #fff;
    cursor: pointer;
    font-size: 20px;
    color: rgb(190, 155, 155);
    padding: 6px;
    opacity: 0.7;
    border-radius: 0px 0px 0px 10px;
}

.list-img-thumbnail .btn-delete-img:hover,
.list-img-thumbnail .btn-delete-img2:hover {
    opacity: 1;
    color: red;
}

.list-img-thumbnail .ant-radio {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
}

.ant-statistic-content {
    font-size: 18px;
}

.ant-descriptions-item-label:hover {
    color: rgba(0, 0, 0, 0.85);
}

.ant-divider-horizontal {
    margin: 10px 0;
}

.icon-delete-comment {
    cursor: pointer;
    font-size: 12px;
    color: #bdc3c7;
}

.ant-comment-inner {
    padding: 0px 0;
}

.ant-comment-actions {
    margin-top: 0;
}

.ant-legacy-form-item {
    margin-bottom: 8px;
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
    width: 55px;
    height: 55px;
}

.ant-upload {
    font-size: 10px;
}

.ant-list-item-meta-avatar:empty {
    margin-right: 0;
}

.ant-list-sm .ant-list-item {
    padding: 8px 0;
}

.ant-tooltip-inner a {
    color: #030aed;
}

.ant-comment-content-detail img {
    max-width: 100%;
}

.ant-comment-content-detail p {
    margin-bottom: 1rem;
}

.ant-tooltip-inner img, .p-description-detail img {
    max-width: 100%;
}

.ant-tooltip-inner p {
    margin: 0px;
}

.table-image img {
    height: 40px;
}

.table-image p {
    margin: 0;
    display: inline;
}

.paramer-content {
    margin-bottom: 10px;
}

.paramer-content>div {
    display: inline-block;
    margin: 0 10px;
}

/* 
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    font-weight: 500;
} */

/* .table-striped-rows tr:nth-child(2n) td {
    background-color: #f7f7f7;
}

.table-striped-rows thead {
    background-color: #f1f1f1;
} */

.font-weight-500 {
    font-weight: 500;
}

.box-cost {
    padding: 15px;
    border: 1px solid #dedede;
    border-radius: 5px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
}

.box-cost .ant-legacy-form-item {
    margin-bottom: 0px;
}

.dragger-upload-store>span {
    padding: 5px !important;
}

.ellipsis-text {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.wrap-imgdemo-design {
    float: left;
    position: relative;
    margin-right: 5px;
}

.btn-delete-imgdemo-design {
    position: absolute;
    z-index: 999;
    color: red;
    cursor: pointer;
    top: 0;
    right: 0;
}

.fix-row-shipping .ant-typography {
    float: left;
    margin-right: 10px !important;
}

.file-png-design-list .ant-image img {
    background: #dedede;
}

.custom-tooltip-chart {
    /* background: #fcfafa; */
    background-color: rgba(255, 255, 255, 0.9);
    width: 90%;
    overflow: hidden;
    padding: 0 10px;
    margin: 5px;
    border: 1px solid #dedede;
    border-radius: 5px;
}

.custom-tooltip-chart .label {
    border-bottom: 1px solid #dedede;
}

.custom-tooltip-chart .ant-tag {
    margin-bottom: 5px;
}

.tooltip-list-item {
    border-bottom: 1px solid #7b7b7b;
    padding: 5px;
    color: #fff;
    margin-bottom: 5px;
}

.tooltip-list-item:last-child {
    border-bottom: none;
}

/* .ant-legacy-form-item-children{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */

/* .is-blacklist * {
    color: red;
} */

/* .is-blacklist sup {
    color: #fff;
} */

.profit {
    color: #00aaff;
    font-weight: 500;
}

.ant-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-btn,
.ant-picker,
.ant-tag {
    border-radius: 5px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 5px 5px 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ant-modal-content {
    border-radius: 5px;
}

.ant-input-number {
    border-radius: 5px !important;
}

.ant-modal-header {
    border-radius: 5px 5px 0 0;
}

.ant-input-group.ant-input-group-compact>*:first-child, .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector, .ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ant-input-group.ant-input-group-compact>*:last-child, .ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector, .ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.no-margin {
    margin: 5px 0;
}

.form-update-auth .ant-form-item-control-input-content {
    text-align: right;
}

.ant-descriptions-small .ant-descriptions-row>th, .ant-descriptions-small .ant-descriptions-row>td {
    padding-bottom: 4px;
    padding-top: 4px;
}

.ant-card-bordered, .ant-collapse {
    border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.wrap-alert .ant-alert-with-description {
    padding: 10px;
    border: none;
    margin: 10px 0;
    border-radius: 5px;
}

.flex-upload .ant-legacy-form-item-children {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;
}

.border-dashed {
    border: 1px dashed #dedede;
}

.icon-action {
    font-size: 1.3em;
}

.h6-title {
    font-size: 14px;
    font-weight: 500;
}

.custom-badge {
    width: 100%;
    height: 100%;
    top: 8px;
}

.custom-badge sup {
    transform: none;
    top: -14px;
    left: 0;
    border-radius: 5px;
    right: auto;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

.wrap-category-tree {
    overflow-y: auto;
    height: 300px;
}

.wrap-category-tree .ecom-checkbox {
    margin: 5px 0;
}

.category-tree {
    list-style-type: none;
    padding-left: 20px;
}

.category-tree li {
    margin-bottom: 5px;
    cursor: pointer;
}

.category-tree li ul {
    padding-left: 20px;
}

.listMenu {
    padding: 5px 8px;
    background: #f4f4f4;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    border: 1px solid #dedede;
}

.iconCollapse {
    padding: 5px;
    cursor: pointer;
    font-weight: 700;
}

/* .menus-items-properties {
    display: none;
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 5px;
    margin-top: -7px;
    border-top: none;
    transition: height 0.3s ease;
}

.menus-items-properties.active {
    display: block;
    height: auto;
} */


/* .list-img-thumbnail {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    border: 1px solid #dedede;
    padding: 5px;
    margin-bottom: 8px;
    width: 55px;
    height: 55px;
    overflow: hidden;
}

.list-img-thumbnail .btn-delete-img {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 18px;
    color: red;
} */

.wrap-attribute {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.wrap-attribute-color-picker {
    display: flex;
    width: 100%;
}

.attribute-color-picker {
    width: 50px;
    height: 32px;
    cursor: pointer;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #d9d9d9;
    border-right: 0
}

.item-color-variants {
    width: 20px;
    display: inline-block;
    border-radius: 5px;
    margin-right: 5px;
    height: 18px;
    line-height: 15px;
}

/* .custom-tab-product .ant-tabs-nav-list {
    width: 100%;
}

.custom-tab-product .ant-tabs-tab {
    width: 100%;
    justify-content: center;
    color: #030aed;
} */

.item-action-btn-drag {
    cursor: grab;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 9999;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.noselect {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: grabbing;
    /* Thay đổi con trỏ khi kéo */
}

.ant-alert {
    border-radius: 5px;
}

.custom-option-col {
    position: relative;
}

.custom-option-col .ant-checkbox-group,
.custom-option-col .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.custom-option-col .ant-checkbox-group label,
.custom-option-col .ant-radio-group label {
    margin: 0;
}